<template>
  <div>
    <a-modal
      v-model="visibleModalAddStudent"
      :title="`Add Student to ${reportType.toUpperCase()} ${
        dataClass.tahunAjar
      } ${dataClass.semester} General Report`"
      @cancel="toggleModalAddStudent"
      :destroyOnClose="true"
      width="70%"
    >
      <AddStudent
        :reportType="reportType"
        :dataClass="dataClass"
        @close-modal="toggleModalAddStudent"
      />
      <template slot="footer">
        <a-button
          class="d-none m-0"
          size="large"
          key="back"
          @click="toggleModalAddStudent"
        >
          Tutup
        </a-button>
      </template>
    </a-modal>
    <div class="mb-2">
      <a-collapse accordion>
        <a-collapse-panel key="1" header="Description">
          <div class="d-flex justify-content-flex-start align-items-center">
            <ul>
              <h4>Social Attitude :</h4>
              <li>H = Honest</li>
              <li>D = Discipline</li>
              <li>P = Polite</li>
              <li>SC = Self Confidence</li>
              <li>C = Caring</li>
              <li>R = Responsible</li>
            </ul>
            <ul>
              <h4>Attendance :</h4>
              <li>S = Sick</li>
              <li>P = Permit</li>
              <li>A = Absent</li>
            </ul>
            <ul>
              <li>
                After click <kbd>Edit</kbd> &nbsp; and select row in the table,
                you can do things below...
              </li>
              <li>
                You can press <kbd>tab</kbd> &nbsp; on your keyboard to move on
                next columns when updating grade.
              </li>
              <li>
                You can press <kbd>space</kbd> &nbsp; on your keyboard to
                navigate between select on select tab when updating word type
                grade.
              </li>
              <li>
                The default in word grade is 'A', if you didn't select the
                value, it will auto-filled by 'A' Grade.
              </li>
            </ul>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loadingTable"
      :scroll="{ x: 0, y: 500 }"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }"
      size="small"
      bordered
    >
      <template slot="title">
        <div class="d-flex align-items-center py-2">
          <a-radio-group
            v-if="!isGrade"
            @change="handleReportType"
            v-model="reportType"
            button-style="solid"
            size="large"
          >
            <a-radio-button value="mid">Mid Report</a-radio-button>
            <a-radio-button value="final">Final Report</a-radio-button>
          </a-radio-group>
          <p v-else>You are in editing mid general report grade now</p>
          <div class="ml-auto" v-if="!isCurriculum">
            <template v-if="isGrade">
              <a-button
                @click="cancelGrade"
                :loading="loadingCancelGrade"
                class="btn btn-outline-info rounded mr-3 d-none"
                type="default"
                size="large"
                icon="close"
                >Cancel</a-button
              >
              <a-button
                @click="cancelGrade"
                :loading="loadingCancelGrade"
                class="btn btn-outline-danger rounded mr-3"
                type="default"
                size="large"
                icon="close"
                >Cancel</a-button
              >
              <a-button
                @click="updateGrade"
                :loading="loadingSaveGrade"
                class="btn btn-outline-success rounded"
                type="default"
                size="large"
                icon="check"
                >Update Grade</a-button
              >
            </template>
            <template v-else>
              <!-- <a-button
                @click="toggleModalAddStudent"
                class="btn btn-outline-primary rounded mr-3"
                type="default"
                size="large"
                icon="plus"
                >Add Student</a-button
              > -->
              <a-button
                v-if="dataTable.length"
                @click="gradeGeneralReport"
                class="btn btn-outline-warning rounded mr-3"
                type="default"
                size="large"
                icon="edit"
                >Grade Student</a-button
              >
              <!-- <a-button
                v-if="dataTable.length"
                :loading="loadingExport"
                @click="exportReport('export')"
                class="btn btn-outline-danger rounded"
                type="default"
                size="large"
                icon="file-pdf"
                >{{
                  loadingExport
                    ? "Exporting"
                    : `Export All ${
                        reportType === "mid" ? "Mid" : "Final"
                      } Report`
                }}</a-button
              > -->
            </template>
          </div>
        </div>
      </template>
      <div slot="Name" slot-scope="text, record">
        <router-link
          v-if="!isGrade && !isCurriculum && record.id_general_report"
          :to="{
            name: 'Detail Homeroom Teacher',
            params: { id: record.id_general_report },
          }"
          >{{ text }}</router-link
        >
        <span v-else>{{ text }}</span>
      </div>
      <div slot="sick" slot-scope="text, record">
        <a-input-number
          v-if="isGrade"
          :value="text"
          size="large"
          class="w-100"
          :min="0"
          :step="1"
          :max="100"
          @change="(e) => handleChange(e, record.key, 'sick')"
        />
        <span v-else>{{ text }}</span>
      </div>
      <div slot="excused" slot-scope="text, record">
        <a-input-number
          v-if="isGrade"
          :value="text"
          size="large"
          class="w-100"
          :min="0"
          :step="1"
          :max="100"
          @change="(e) => handleChange(e, record.key, 'excused')"
        />
        <span v-else>{{ text }}</span>
      </div>
      <div slot="absent" slot-scope="text, record">
        <a-input-number
          v-if="isGrade"
          :value="text"
          size="large"
          class="w-100"
          :min="0"
          :step="1"
          :max="100"
          @change="(e) => handleChange(e, record.key, 'absent')"
        />
        <span v-else>{{ text }}</span>
      </div>
      <div slot="spiritual" slot-scope="text, record">
        <a-select
          v-if="isGrade"
          :default-value="text === '-' || !text ? 'A' : text"
          size="large"
          @change="(e) => handleChange(e, record.key, 'spiritual')"
          style="width: 60px"
        >
          <a-select-option
            v-for="item in selectedAttitude"
            :key="item.id"
            :value="item.grade"
            >{{ item.grade }}</a-select-option
          >
        </a-select>
        <span v-else>{{ text || "-" }}</span>
      </div>
      <div slot="honest" slot-scope="text, record">
        <a-select
          v-if="isGrade"
          :default-value="text === '-' || !text ? 'A' : text"
          size="large"
          @change="(e) => handleChange(e, record.key, 'honest')"
          style="width: 60px"
        >
          <a-select-option
            v-for="item in selectedAttitude"
            :key="item.id"
            :value="item.grade"
            >{{ item.grade }}</a-select-option
          >
        </a-select>
        <span v-else>{{ text || "-" }}</span>
      </div>
      <div slot="discipline" slot-scope="text, record">
        <a-select
          v-if="isGrade"
          :default-value="text === '-' || !text ? 'A' : text"
          size="large"
          @change="(e) => handleChange(e, record.key, 'discipline')"
          style="width: 60px"
        >
          <a-select-option
            v-for="item in selectedAttitude"
            :key="item.id"
            :value="item.grade"
            >{{ item.grade }}</a-select-option
          >
        </a-select>
        <span v-else>{{ text || "-" }}</span>
      </div>
      <div slot="politeness" slot-scope="text, record">
        <a-select
          v-if="isGrade"
          :default-value="text === '-' || !text ? 'A' : text"
          size="large"
          @change="(e) => handleChange(e, record.key, 'politeness')"
          style="width: 60px"
        >
          <a-select-option
            v-for="item in selectedAttitude"
            :key="item.id"
            :value="item.grade"
            >{{ item.grade }}</a-select-option
          >
        </a-select>
        <span v-else>{{ text || "-" }}</span>
      </div>
      <div slot="self_confidence" slot-scope="text, record">
        <a-select
          v-if="isGrade"
          :default-value="text === '-' || !text ? 'A' : text"
          size="large"
          @change="(e) => handleChange(e, record.key, 'self_confidence')"
          style="width: 60px"
        >
          <a-select-option
            v-for="item in selectedAttitude"
            :key="item.id"
            :value="item.grade"
            >{{ item.grade }}</a-select-option
          >
        </a-select>
        <span v-else>{{ text || "-" }}</span>
      </div>
      <div slot="caring" slot-scope="text, record">
        <a-select
          v-if="isGrade"
          :default-value="text === '-' || !text ? 'A' : text"
          size="large"
          @change="(e) => handleChange(e, record.key, 'caring')"
          style="width: 60px"
        >
          <a-select-option
            v-for="item in selectedAttitude"
            :key="item.id"
            :value="item.grade"
            >{{ item.grade }}</a-select-option
          >
        </a-select>
        <span v-else>{{ text || "-" }}</span>
      </div>
      <div slot="responsible" slot-scope="text, record">
        <a-select
          v-if="isGrade"
          :default-value="text === '-' || !text ? 'A' : text"
          size="large"
          @change="(e) => handleChange(e, record.key, 'responsible')"
          style="width: 60px"
        >
          <a-select-option
            v-for="item in selectedAttitude"
            :key="item.id"
            :value="item.grade"
            >{{ item.grade }}</a-select-option
          >
        </a-select>
        <span v-else>{{ text || "-" }}</span>
      </div>
    </a-table>
    <div
      v-if="dataGeneralReports.length && reportType === 'mid'"
      class="d-none"
    >
      <div id="reportHtml" class="w-100">
        <div
          v-for="generalReport in dataGeneralReports"
          :key="generalReport.id"
        >
          <report-mid-view
            :format="isExported ? { width: '650px', height: '1037px' } : null"
            :configSchool="configSchool"
            :generalReport="generalReport"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="dataGeneralReports.length && reportType === 'final'"
      class="d-none"
    >
      <div id="reportHtml" class="w-100">
        <div
          v-for="generalReport in dataGeneralReports"
          :key="generalReport.id"
        >
          <report-final-view
            print
            :format="isExported ? { width: '670px', height: '1045px' } : null"
            :configSchool="configSchool"
            :generalReport="generalReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configSchool from '@/config'
import JsPdf from 'jspdf'
const AddStudent = () => import('@/components/app/Homeroom/TableGeneralReport/AddStudent')
const ReportMidView = () => import('@/components/app/Report/Mid')
const ReportFinalView = () => import('@/components/app/Report/Final')
const columns = [
  {
    title: 'NO',
    dataIndex: 'no',
    key: 'No',
    width: 50,
    // fixed: 'left',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'Name',
    width: 200,
    elipsis: true,
    // fixed: 'left',
    align: 'left',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'Attendance',
    // width: 105,
    align: 'center',
    children: [
      {
        title: 'S',
        dataIndex: 'sick',
        key: 'Sick',
        width: 50,
        align: 'center',
        scopedSlots: { customRender: 'sick' },
      },
      {
        title: 'P',
        dataIndex: 'excused',
        key: 'Excused',
        width: 50,
        align: 'center',
        scopedSlots: { customRender: 'excused' },
      },
      {
        title: 'A',
        dataIndex: 'absent',
        key: 'Absent',
        width: 50,
        align: 'center',
        scopedSlots: { customRender: 'absent' },
      },
    ],
  },
  {
    title: 'Spiritual',
    dataIndex: 'spiritual',
    key: 'Spiritual',
    width: 60,
    align: 'center',
    scopedSlots: { customRender: 'spiritual' },
  },
  {
    title: 'Social',
    // width: 210,
    align: 'center',
    children: [
      {
        title: 'H',
        dataIndex: 'honest',
        key: 'Honest',
        width: 60,
        align: 'center',
        scopedSlots: { customRender: 'honest' },
      },
      {
        title: 'D',
        dataIndex: 'discipline',
        key: 'Discipline',
        width: 60,
        align: 'center',
        scopedSlots: { customRender: 'discipline' },
      },
      {
        title: 'P',
        dataIndex: 'politeness',
        key: 'Polite',
        width: 60,
        align: 'center',
        scopedSlots: { customRender: 'politeness' },
      },
      {
        title: 'SC',
        dataIndex: 'self_confidence',
        key: 'SelfConfidence',
        width: 60,
        align: 'center',
        scopedSlots: { customRender: 'self_confidence' },
      },
      {
        title: 'C',
        dataIndex: 'caring',
        key: 'Caring',
        width: 60,
        align: 'center',
        scopedSlots: { customRender: 'caring' },
      },
      {
        title: 'R',
        dataIndex: 'responsible',
        key: 'Responsible',
        width: 60,
        align: 'center',
        scopedSlots: { customRender: 'responsible' },
      },
    ],
  },
]

export default {
  props: {
    // reportType: {
    //   type: String,
    //   required: true,
    // },
    dataClass: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddStudent,
    ReportMidView,
    ReportFinalView,
  },
  data() {
    return {
      columns,
      configSchool,
      idWaliKelas: null,
      isCurriculum: false,
      visibleModalAddStudent: false,
      loadingTable: false,
      loadingCancelGrade: false,
      loadingSaveGrade: false,
      loadingExport: false,
      modalConfirmGradeVisible: false,
      isGrade: false,
      isExported: true,
      reportType: 'mid',
      dataTable: [],
      dataGeneralReports: [],
      selectedAttitude: [
        {
          id: 1,
          grade: 'A',
        },
        {
          id: 2,
          grade: 'B',
        },
        {
          id: 3,
          grade: 'C',
        },
        {
          id: 4,
          grade: 'D',
        },
        {
          id: 5,
          grade: 'E',
        },
      ],
    }
  },
  methods: {
    handleReportType(e) {
      this.reportType = e.target.value
      this.fetchDataTableGeneral()
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log('validateDataInput data[i]', data[i])
        if (data[i].Sick === '-' || data[i].Sick === '') {
          data[i].Sick = null
        } else if (data[i].Excused === '-' || data[i].Excused === '') {
          data[i].Excused = null
        } else if (data[i].Absent === '-' || data[i].Absent === '') {
          data[i].Absent = null
        }
        if (isNaN(Number(data[i].Sick)) || isNaN(Number(data[i].Excused)) || isNaN(Number(data[i].Absent))) {
          isInvalid = true
          message = `Sorry, ${data[i].Name}'s Attendance must be numbers. ${i}`
          break
        } else if (Number(data[i].Sick) > 100 || Number(data[i].Sick) < 0 || Number(data[i].Excused) > 100 || Number(data[i].Excused) < 0 || Number(data[i].Absent) > 100 || Number(data[i].Absent) < 0) {
          isInvalid = true
          message = `Sorry, ${data[i].Name}'s Attendance must be between 0 and 100.`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    toggleModalAddStudent() {
      this.visibleModalAddStudent = !this.visibleModalAddStudent
      if (!this.visibleModalAddStudent) {
        this.fetchDataTableGeneral()
      }
    },
    cancelGrade() {
      this.loadingCancelGrade = true
      this.fetchDataTableGeneral()
    },
    updateGrade() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div class="d-flex flex-column"><p>Are you sure want to update general report grade?</p></div>
        ),
        onOk: () => {
          this.loadingSaveGrade = true
          this.$store.dispatch('homeroom/UPDATE_GENERAL_REPORT', {
            idGuru: this.idWaliKelas,
            data: this.dataTable,
          })
            .then(res => {
              this.$notification.success({
                message: 'Success',
                description: 'General Report Grade has been updated',
              })
            })
            .catch(err => {
              this.$notification.error({
                message: 'Error',
                description: 'General Report Grade has not been updated',
              })
              console.log(err)
            })
            .finally(() => {
              this.loadingSaveGrade = false
              this.fetchDataTableGeneral()
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Update Now',
        cancelText: 'Cancel',
      })
    },
    gradeGeneralReport() {
      this.isGrade = true
    },
    handleChange(value, key, column) {
      const target = this.dataTable.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        console.log(target)
      }
    },
    async exportReport(type = 'export') {
      this.isExported = await true
      if (type === 'export') this.loadingExport = true
      else if (type === ' print') this.loadingPrint = true
      // this.$refs.html2Pdf.generatePdf()
      const data = document.getElementById('reportHtml')
      const doc = new JsPdf({
        // orientation: 'p',
        unit: 'px',
        // format: 'a4',
        // putOnlyUsedFonts: true,
      })
      doc.html(data, {
        callback: (doc) => {
          this.isExported = false
          if (type === 'print' || type === 'export') {
            const tipeReport = this.reportType.toUpperCase()
            const kelas = this.dataClass.kelas.toUpperCase()
            const semester = this.dataClass.semester.toUpperCase()
            const tahunAjaran = this.dataClass.tahunAjar
            const title = `${kelas} REPORT ${tipeReport} ${tahunAjaran} ${semester}`
            if (type === 'export') doc.save(title + '.pdf')
            else {
              // doc.autoPrint()
              // doc.output('dataurlnewwindow')
              window.open(URL.createObjectURL(doc.output('blob')))
            }
            this.loadingExport = false
            this.loadingPrint = false
          } else {
            console.log('Params required')
          }
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
          scale: 0.6,
        },
        margin: [10, 10, 10, 10],
      })
      // doc.text('Hello world!', 10, 10)
      // doc.save('a4.pdf')
    },
    async fetchDataTableGeneral() {
      this.loadingTable = true
      try {
        const idKelas = this.dataClass.idKelas
        const type = this.reportType
        const data = await this.$store.dispatch('homeroom/FETCH_HOMEROOM_GENERAL_REPORT', { idKelas, type })
        this.dataTable = data
      } catch (error) {
        console.log(error)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of fetching General Report encountered problems. Please try again later',
        })
      } finally {
        this.loadingTable = false
        this.isGrade = false
        this.loadingCancelGrade = false
      }
    },
  },
  mounted() {
    if (this.$route.query.isCurriculum) {
      this.isCurriculum = true
      this.idWaliKelas = this.$route.query.id_walikelas
    } else {
      this.idWaliKelas = this.user.id
    }
    this.fetchDataTableGeneral()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>
